/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';

import logo from '../assets/images/logo.png'; // Adjust the path and filename as needed

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      <img src={logo} alt="EdgyDev Logo" width={200} />
    </Button>
  );
}
