/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { InlineWidget } from 'react-calendly';

import Fade from 'react-reveal/Fade';

export default function CalendlyMeeting() {
  return (
    <div className="bg-gray-50 py-20 mb-24 sm:mb-18 xl:mb-16">
      <div className="container mx-auto">
        <Fade bottom>
          <h1 className="text-5xl text-theme-blue text-center font-bold">
            Book a Consultation
          </h1>

          <p className="font-light text-lg text-gray-400 text-center mb-12 sm:mb-5 xl:mb-0">
            Schedule a Meeting to Discuss Your Project Needs.
          </p>
        </Fade>

        <InlineWidget styles={{ minWidth: '320px', height: '750px' }} url="https://calendly.com/rafeh-edgydev/custom-software-consultation" />
      </div>
    </div>
  );
}
