/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

export default function AllTeam({ data }) {
  return (
    <section className="container mx-0 sm:mx-auto">
      <div className="grid lg:grid-cols-2 gap-y-8 gap-x-12 justify-items-center">
        {
          data.map((item, index) => (
            <Fade bottom delay={2 * index}>
              <div>
                <div className="teams-card flex flex-col p-10 rounded-xl shadow-xl border border-light-theme-purple justify-center transform transition duration-500 hover:scale-105">
                  <div className="flex justify-center xl:mb-5">
                    <img src={item.imageUrl} alt="Team Member" className="flex w-32 h-32 rounded-full" />
                  </div>
                  <h2 className="text-theme-blue text-center text-xl">{item.name}</h2>
                  <p className="font-light text-gray-400 text-center mb-3">{item.position}</p>
                  <p className="font-light text-xl text-gray-400 leading-relaxed">{item.desc}</p>
                </div>
              </div>
            </Fade>
          ))
        }
      </div>
    </section>
  );
}
