/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import Fade from 'react-reveal/Fade';

export default function Portfolio() {
  return (
    <section className="container mx-auto flex flex-col items-center mt-20">
      <Fade bottom>
        <h1 className="text-5xl text-theme-blue text-center font-bold">
          Performance Overview
        </h1>

        <p className="font-light text-lg text-gray-400 text-center mb-12">
          Empowering Business Growth with Innovative Solutions
        </p>
      </Fade>
      <Fade bottom>
        <div className="experience-section">
          <div className="experience-info">
            <div className="experience-years">
              <span className="years-number">12+</span>
              <span className="years-text">Projects Completed with Excellence</span>
            </div>
            <h2>Building Success Stories Together</h2>
          </div>
          <div className="experience-details">
            <h3>Creating Ideas That Matters To People.</h3>
            <div className="progress-bar">
              <div className="progress-title">Business Goal</div>
              <div className="progress">
                <div className="progress-inner" style={{ width: '85%' }}> </div>
              </div>
              <div className="progress-percentage">85%</div>
            </div>
            <div className="progress-bar">
              <div className="progress-title">Traffic Growth</div>
              <div className="progress">
                <div className="progress-inner" style={{ width: '90%' }}> </div>
              </div>
              <div className="progress-percentage">90%</div>
            </div>
            <div className="progress-bar">
              <div className="progress-title">Competitor Research</div>
              <div className="progress">
                <div className="progress-inner" style={{ width: '80%' }}> </div>
              </div>
              <div className="progress-percentage">80%</div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}
